<template>
  <table class="striped client-table">
    <caption v-html="caption" v-if="caption" />
    <colgroup>
      <col width="15%" />
      <col width="15%" />
      <col width="21%" />
      <col width="15%" />
      <col width="15%" />
      <col width="15%" />
    </colgroup>
    <thead>
      <tr>
        <th>Client Number</th>
        <th>Client Name</th>
        <th>Client Address</th>
        <th>Phone Number</th>
        <th>Medicare No.</th>
        <th>Client Agency No</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="client in paginatedClients"
        :key="client.id"
        :class="
          canSelect && (selectedId === client.id ? 'selected' : 'selectable')
        "
        @click="handleSelect(client)"
      >
        <td>{{ client.clientNumber }}</td>
        <td>{{ getFullName(client) }}</td>
        <td>
          <address v-html="getAddress(client)" />
        </td>
        <td>{{ formatPhoneNumber(getPhoneNumber(client)) }}</td>
        <td>{{ formatMedicareNumber(client.medicareNumber) }}</td>
        <td>{{ client.fcsNumber }}</td>
      </tr>
    </tbody>
    <tfoot v-if="hasPages">
      <tr>
        <td class="page-controls" colspan="6">
          <icon-button
            :disabled="!hasPrevPage"
            name="prev"
            @click="goToPrevPage"
          />

          <icon-button
            :disabled="!hasNextPage"
            name="next"
            @click="goToNextPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { pagination } from "../../mixins/pagination";

export default {
  mixins: [pagination("clients")],

  props: {
    canSelect: {
      type: Boolean,
      default: true,
    },
    caption: {
      type: String,
      default: undefined,
    },
    clients: {
      type: Array,
    },
    pageCount: {
      types: Number,
      default: 1,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    hasNextPage() {
      return this.pageNumber < this.pageCount;
    },
    hasPages() {
      return this.pageCount > 1;
    },
    hasPrevPage() {
      return this.pageNumber > 1;
    }
  },

  methods: {
    getAddress(client) {
      const address =
        client?.addresses?.find((a) => a.mailing) ??
        client?.facility?.addresses?.slice(-1)?.[0];

      return [
        address?.address1,
        address?.address2,
        `${address?.city?.name ?? ""}, ${
          address?.province?.abbreviation ?? ""
        }`.replace(/(^\s*,\s*|\s*,\s*$)/g, ""),
        address?.postalCode,
      ]
        .filter((v) => v)
        .join("<br/>");
    },
    getPhoneNumber(client) {
      return client?.phoneNumbers?.find((p) => p.type.id === 1)?.number;
    },
    handleSelect(client) {
      this.$emit("select", client);
    },
    goToFirstPage() {
      this.$emit("page-change", 1);
    },
    goToLastPage() {
      this.$emit("page-change", this.pageCount);
    },
    goToNextPage() {
      this.$emit("page-change", Math.min(this.pageNumber + 1, this.pageCount));
    },
    goToPrevPage() {
      this.$emit("page-change", Math.max(this.pageNumber - 1, 1));
    },
  },
};
</script>

<style scoped>
.client-table td {
  vertical-align: top;
}

.client-table address {
  font-style: normal;
}
</style>
<template>
  <section class="table">
    <table class="striped equipment-table">
      <caption v-html="caption" v-if="caption" />
      <colgroup>
        <col width="3%" />
        <col :width="can('READ_EQUIPMENT') ? '14%' : '16%'" />
        <col width="12%" />
        <col width="12%" />
        <col :width="can('READ_EQUIPMENT') ? '12%' : '20%'" />
        <col :width="can('READ_EQUIPMENT') ? '12%' : '20%'" />
        <col :width="can('READ_EQUIPMENT') ? '12%' : '20%'" />
        <col :width="can('READ_EQUIPMENT') ? '12%' : '20%'" />
        <col width="14%" v-if="can('READ_EQUIPMENT')" />
      </colgroup>
      <thead>
        <tr>
          <th></th>
          <th>{{ getStaticName($store.getters.user, "Equipment Number", "Numéro d'équipement") }}</th>
          <th>{{ getStaticName($store.getters.user, "Serial No.", "No de série") }}</th>
          <th>{{ getStaticName($store.getters.user, "Model No.", "No de modèle") }}</th>
          <th>{{ getStaticName($store.getters.user, "Equipment Category", "Catégorie d'équipement") }}</th>
          <th>{{ getStaticName($store.getters.user, "Equipment Subcategory", "Sous-catégorie d'équipement") }}</th>
          <th>{{ getStaticName($store.getters.user, "Item", "Article") }}</th>
          <th>{{ getStaticName($store.getters.user, "Last Return Date", "Date de retour") }}</th>
          <th>{{ getStaticName($store.getters.user, "Status", "Statut") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="equipment in paginatedSortedEquipment"
          :key="equipment.id"
          :class="
            canSelect &&
            (selectedId === equipment.id ? 'selected' : 'selectable')
          "
          @click="handleSelect(equipment)"
        >
          <td>
            <span v-if="equipment.reserved" class="table-reservation-icon">
              <img alt="Equipment Items" src="../../assets/icons/flagged.svg" />
            </span>
          </td>
          <td>
            {{ getEquipmentNumber(equipment) }}
          </td>
          <td>{{ equipment.serialNumber }}</td>
          <td>{{ equipment.modelNumber }}</td>
          <td>{{ getName(equipment.category, $store.getters.user) }}</td>
          <td>{{ getName(equipment.subcategory, $store.getters.user) }}</td>
          <td>{{ getName(equipment.item, $store.getters.user) }}</td>
          <td>{{ (isAvailable(equipment) && formatTimestamp(equipment.lastReturnDate)) || null }}</td>
          <td>
            {{ getEquipmentStatus(equipment) }}
          </td>
        </tr>
      </tbody>
      <tfoot v-if="hasPages">
        <tr>
          <td class="page-controls" :colspan="9">
            <icon-button
              :disabled="!hasPrevPage"
              name="prev"
              @click="goToPrevPage"
            />

            <icon-button
              :disabled="!hasNextPage"
              name="next"
              @click="goToNextPage"
            />
          </td>
        </tr>
      </tfoot>
    </table>
    <div
      class="picture-mask pick-archived-picture-modal"
      v-bind:class="{ show: !showModal }"
    >
      <div class="picture-wrapper">
        <div class="picture-body">
          <div class="picture-close">
            <span v-on:click="closeArchivePicModal">X</span>
          </div>
          <div class="picture-header">
            <h2>Equipment Images</h2>
          </div>
          <div class="picture-message">
            <div class="section-picture-main">
              <div class="no-image-text" v-if="equipmentPhotos.length == 0">
                No pictures were found for this equipment item
              </div>
              <UploadedImage
                v-for="(image, index) in equipmentPhotos"
                v-bind:key="index"
                v-bind:value="index"
                :image="image"
                :index="index"
                :show-info="false"
              ></UploadedImage>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { pagination } from "../../mixins/pagination";
import UploadedImage from "../common/UploadedImage";

export default {
  mixins: [pagination("sortedEquipment")],

  components: {
    UploadedImage,
  },

  data() {
    return {
      equipmentPhotos: [],
      showModal: false,
    };
  },

  props: {
    canSelect: {
      type: Boolean,
      default: true,
    },
    caption: {
      type: String,
      default: undefined,
    },
    equipment: {
      type: Array,
    },
    pageCount: {
      types: Number,
      default: 1,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    hasNextPage() {
      return this.pageNumber < this.pageCount;
    },
    hasPages() {
      return this.pageCount > 1;
    },
    hasPrevPage() {
      return this.pageNumber > 1;
    },
    sortedEquipment() {
      if(this.$store.getters.user?.roles?.includes("REFERRAL_AGENT")) {
        return this.equipment.sort((a,b) => {
          return (a.reserved === b.reserved) ? 0 : a.reserved ? -1: 1;
        });
      }

      return this.equipment;
    },
  },

  methods: {
    closeArchivePicModal() {
      this.showModal = !this.showModal;
    },
    goToFirstPage() {
      this.$emit("page-change", 1);
    },
    goToLastPage() {
      this.$emit("page-change", this.pageCount);
    },
    goToNextPage() {
      this.$emit("page-change", Math.min(this.pageNumber + 1, this.pageCount));
    },
    goToPrevPage() {
      this.$emit("page-change", Math.max(this.pageNumber - 1, 1));
    },
    handleSelect(equipment) {
      this.$emit("select", equipment);
    },
    showImages(equipment) {
      this.showModal = !this.showModal;
      this.equipmentPhotos = equipment.photos;
    },
  },
};
</script>

<style scoped>
.equipment-table td {
  vertical-align: top;
}

.equipment-table address {
  font-style: normal;
}

.show {
  display: none;
}

.uploaded-image-single-element {
  grid-template-columns: 1fr;
}

.section-picture-main .uploaded-image-single-information {
  display: none;
}

.table-reservation-icon {
  display: inline;
}

.table-reservation-icon img{
  max-height: 2.5em;
  max-width: 1em;
}
</style>
<template>
  <main id="app">
    <loader :hide-scrollbar="true" :value="$data._percentComplete" v-if="$data._isFetchingData" />
    <router-view />
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

const resetCustomValidity = (e) => {
  e.target.setCustomValidity("");
};

export default {
  data() {
    return {
      debounce: {},
      _isFetchingData: false,
      _percentComplete: 0,
    };
  },

  methods: {
    ...mapActions("user", ["getSession"]),
  },

  computed: {
    ...mapGetters("api", ["isFetchingData", "percentComplete"]),
  },

  watch: {
    isFetchingData: {
      immediate: true,
      handler(to, from) {
        clearTimeout(this.debounce.isFetchingData);

        this.debounce.isFetchingData = setTimeout(
          () => (this.$data._isFetchingData = to),
          !to && from ? 600 : 0
        );
      },
    },
    percentComplete: {
      immediate: true,
      handler(to, from) {
        clearTimeout(this.debounce.percentComplete);
        !to && from && (this.$data._percentComplete = 100);

        this.debounce.percentComplete = setTimeout(
          () => (this.$data._percentComplete = to),
          !to && from ? 600 : 0
        );
      },
    },
  },

  created() {
    this.getSession();
    window.addEventListener("beforeunload", this.confirmOnUnload);
    window.addEventListener("input", resetCustomValidity);
  },

  beforeDestroy() {
    window.removeEventListener("input", resetCustomValidity);
    window.removeEventListener("beforeunload", this.confirmOnUnload);
  },
};
</script>

<style>
html {
  height: 100%;
  width: 100%;
}

body {
  background: var(--primary-lightest-gray);
  min-height: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}

#app {
  align-items: center;
  color: var(--primary-black);
  display: flex;
  flex-direction: column;
  font-family: Muli, sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  position: relative;
  width: 100%;
}

.page-controls {
  text-align: right;
  vertical-align: middle;
}

.page-controls > * {
  margin-left: 0.5rem;
}

td.page-controls:last-child {
  padding-right: 0;
}
</style>